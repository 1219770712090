
































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Period from "@/views/components/inputs/period.vue";
import BarChart from "@/views/components/charts/BarChart";
import { AxiosResponse } from "axios";
import GeneralApi from "@/networks/GeneralApi";
import store from "@/store";
import moment from "moment-jalaali";
import Enum from "@/config/enum";

@Component({
  components: {
    BarChart,
    period: Period
  }
})
export default class Dashboard extends Vue {
  private roles = localStorage.getItem("roles") || [];
  private options = Enum.ChartOptions;

  private selectedPeriod = "-30 days";
  private items: Record<string, any> = {};
  private costs = {
    user_add: 0,
    call_answer: 0,
    event_add: 0,
    contract_add: 0
  };

  mounted() {
    this.dashboard();
  }

  public async dashboard(): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().financialDashboard({
        period: this.selectedPeriod
      });
      this.items = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("selectedPeriod")
  public onSelectedPeriodChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.dashboard();
    }
  }
}
